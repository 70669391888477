@font-face {
  font-family: 'icomoon';
  src: url(src/styles/fonts/icomoon.eot);
  src: url(src/styles/fonts/icomoon.eot#iefix) format("embedded-opentype"), url(src/styles/fonts/icomoon.ttf) format("truetype"), url(src/styles/fonts/icomoon.woff) format("woff"), url(src/styles/fonts/icomoon.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-linkedin:before {
  content: "\e900"; }

.icon-twitter:before {
  content: "\e901"; }

.icon-keyboard_arrow_down:before {
  content: "\e902"; }

html, body {
  font-family: 'Fira Sans', Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0; }

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px; }

.entrance {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: #456B8D; }
  .entrance .text {
    margin: 20vh 30vh;
    color: white;
    font-size: 30px; }
    .entrance .text hr {
      border-top: 2px solid white; }
  .entrance button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: none;
    margin-left: 30vh;
    background-color: white;
    border-radius: 50%;
    font-size: 30px;
    cursor: pointer;
    transition: .3s; }
    .entrance button:hover {
      background-color: transparent;
      border: 2px solid white;
      color: white; }

.seperator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: #E7E7E7;
  color: #383838;
  margin: 0; }

.item {
  display: flex;
  margin: 20px 0;
  flex-direction: column;
  max-width: 900px; }
  .item .info {
    background-color: #456B8D;
    height: 40px;
    border-radius: 5px;
    padding: 5px 20px;
    margin-left: auto;
    color: white;
    display: flex;
    align-items: center;
    text-decoration: none; }
  .item img {
    width: 100%;
    height: auto;
    margin-top: 10px;
    border: 2px solid #E7E7E7; }
  .item .title {
    font-size: 24px; }

.contact {
  display: flex;
  max-width: 900px;
  width: 100%; }
  .contact .left {
    display: flex;
    flex-direction: column; }
    .contact .left p {
      margin: 2px; }
    .contact .left a {
      padding-top: 10px;
      padding-bottom: 20px;
      color: #383838; }
  .contact .right {
    margin-left: auto;
    display: flex;
    align-items: center; }
    .contact .right a {
      display: flex;
      background-color: #456B8D;
      margin-left: 10px;
      text-decoration: none;
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      border-radius: 50%; }
      .contact .right a i {
        color: white; }

/* Mobile Styles */
@media only screen and (max-width: 768px) {
  .entrance .text {
    margin: 14vh 40px; }
  .entrance button {
    margin-left: 40px; }
  .item {
    margin: 20px 40px; }
  .contact {
    margin: 0 40px; } }
